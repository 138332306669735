class Select {

    constructor(selector) {
        this.selects = document.querySelectorAll(selector);
    }

    renderSelect() {
        const locales = ['en', 'ru', 'fr', 'it', 'es', 'de'];
        let [, pageLocale] = window.location.pathname.split('/');
        this.selects.forEach(select => {
            const selectItems = select.querySelectorAll('[data-language]');

            if (!locales.includes(pageLocale)) {
                pageLocale = 'en';
            }
            //console.log(pageLocale);
            selectItems.forEach(item => {
                item.classList.remove("selected");
            });

            if (pageLocale) {
                select.querySelector(`[data-language=${pageLocale}]`).classList.add("selected");
            } else {
                select.querySelector('[data-language="en"]').classList.add("selected");
            }

            select.addEventListener("click", (e) => {
                const target = e.target;

                if (target.closest(".selected")) {
                    e.preventDefault();
                    select.classList.toggle("select-open")
                }
            })

            document.addEventListener("click", (e) => {
                if (!e.target.closest(".select")) {
                    select.classList.remove("select-open");
                }
            })
        })
    }

}

export default Select;
