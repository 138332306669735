import Accordion from "../functions/accordion";
import Tabs from "../functions/tabs";

if (document.querySelector('.steps__accordion')) {
  new Accordion(".steps__accordion").renderAccordion();
}

if (document.querySelector('.steps')) {
  new Tabs(".steps").renderTab();
}
