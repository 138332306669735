import { Splide } from "@splidejs/splide";

if (document.querySelector('.functionality')) {
  const functionalitySlider = new Splide('.functionality__slider', {
    classes: {
      pagination: "functionality__pagination",
      page: "functionality__bullet splide__pagination__page"
    },
    pagination: false,
    perPage: 1,
    gap: "30px",
    padding: {
      right: "350px"
    },
    breakpoints: {
      992: {
        padding: {
          right: "100px"
        }
      },

      768: {
        padding: {
          right: "0px"
        }
      }
    }
  });

  functionalitySlider.mount();
}

